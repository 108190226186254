import React from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"
import Card from "@components/containers/card"
import Section from "@components/containers/section"
import SectionContent from "@components/containers/section-content"
import Heading from "@components/content/heading"
import DreamCard from "@components/containers/dream-card"
import { Columns } from "@components/grid"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import Rows from "@src/components/grid/rows"
import Content from "@src/components/grid/content"

const useStyles = createUseStyles(theme => ({
  headingColumn: {
    gridTemplateColumns: "1fr 700px 1fr",
    "& > header": {
      gridColumn: 2,
    },
  },
  container: {
    marginTop: 30,
    display: "grid",
    width: "100%",
    maxWidth: 1299,
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr 1fr 1fr",
    gridTemplateAreas:
      '"one one one one one one" "two two two three three three" "four four four four four four" "five five six six six six"',
    gap: "24px 24px",

    "& > div.card": {
      backgroundColor: theme.colors.lights.white,
      overflow: "hidden",
      position: "relative",
      // Hack for Safari rounded corners
      transform: "translateZ(0)",
    },

    "& p": {
      fontSize: 18,
    },

    "& h3": {
      marginBottom: theme.spacing.xs,
    },
  },
  imageFourWrapper: {},
  accent: {
    transform: "translate(-3%, 200%)",
  },
  accent2: {
    transform: "translate(100%, 150%)",
  },
  one: {
    gridArea: "one",
    backgroundColor: `#6223a9 !important`,
    "--text-color": "#ffffff",

    "& p": {
      maxWidth: 490,
    },
  },
  imageOne: {
    position: "absolute",
    inset: 0,
    zIndex: 0,

    "& .gatsby-image-wrapper": {
      position: "unset",
    },
  },
  cardOneContent: {
    zIndex: 1,
  },
  two: {
    gridArea: "two",
  },
  imageTwo: {
    maxWidth: "none",
  },
  imageTwoWrapper: {
    margin: `-${theme.spacing.lg} -${theme.spacing.lg} 0`,
    overflow: "hidden",
  },
  three: {
    gridArea: "three",
  },
  imageThree: {
    maxWidth: "none",
  },
  imageThreeWrapper: {
    margin: `-${theme.spacing.lg} -${theme.spacing.lg} 0`,
    overflow: "hidden",
  },
  four: {
    gridArea: "four",
    overflow: "hidden",
    backgroundColor: `#ffc31f !important`,
  },
  contentFour: {
    marginTop: "auto",
    paddingLeft: 40,
  },
  imageFour: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "100%",
  },
  five: {
    gridArea: "five",
    width: "auto",
    display: "flex !important",
    flexDirection: "column",
    minHeight: 400,

    "& *": {
      color: "#fff",
    },

    "& > div": {
      marginTop: "auto",
    },
  },
  six: {
    gridArea: "six",
    display: "flex !important",
    flexDirection: "column",

    "& p": {
      maxWidth: 465,
    },
  },
  imageSix: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "100%",
    maxHeight: 450,
  },
  "@media(max-width: 1200px)": {
    imageFour: {
      opacity: 0.3,
    },
    accent: {
      transform: "translate(5%, 0)",
    },
    accent2: {
      transform: "translate(90%, 150%)",
      zIndex: -1,
    },
  },
  "@media(max-width: 1000px)": {
    imageSix: {
      position: "relative",
      marginLeft: theme.spacing.lg,
      marginBottom: `-${theme.spacing.lg}`,
    },
  },
  "@media(max-width: 768px)": {
    headingColumn: {
      gridTemplateColumns: "1fr",
      "& > header": {
        gridColumn: 1,
      },
    },
    imageOne: {
      position: "relative",
      margin: `1rem -${theme.spacing.lg} -${theme.spacing.lg}`,
    },
    accent: {
      transform: "translate(15%, -700%)",
    },
    container: {
      display: "block",
      padding: 0,
      marginTop: 20,

      "& > div.card": {
        margin: "16px 24px",
        padding: "3rem 2rem",
      },
    },
    imageTwo: {
      maxWidth: `calc(100vw)`,
      width: "100%",
    },
    imageThree: {
      maxWidth: `calc(100vw)`,
      width: "100%",
    },
    four: {
      paddingBottom: "0 !important",
    },
    contentFour: {
      paddingLeft: 0,
      gridRow: 1,
    },
    imageFour: {
      opacity: 1,
      position: "relative",
      width: "100%",
    },
    imageFourWrapper: {
      marginRight: -32,
    },
  },
  "@media (max-width: 400px)": {
    accent: {
      transform: "translate(20%, -800%)",
    },
  },
}))

function BenefitsGrid(props) {
  const { data } = props
  const { card1, card2, card3, card4, card5, card6, heading } = data
  const cx = useStyles({ ...props })

  return (
    <Section theme="light">
      <SectionContent paddingBot="xs" paddingTop="lg">
        <Rows gap="xl">
          <Columns className={cx.headingColumn}>
            <Heading alignment="center" level={4} className="h1">
              {parse(heading)}
            </Heading>
          </Columns>
          <div className={cx.container}>
            <Card className={cx.one} styleType="solid" padding="lg">
              <Columns count="2,1">
                <div className={cx.cardOneContent}>
                  <Content paddingLeft="none" paddingRight="none">
                    <Heading level={2} className="h3">
                      {card1.heading}
                    </Heading>
                    <p>{card1.content}</p>
                  </Content>
                </div>
                <div className={cx.imageOne}>
                  <GatsbyImage
                    image={
                      card1.image.localFile.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </Columns>
            </Card>
            <Card className={cx.two} styleType="solid" padding="lg">
              <div className={cx.imageTwoWrapper}>
                <GatsbyImage
                  image={card2.image.localFile.childImageSharp.gatsbyImageData}
                  alt=""
                  className={cx.imageTwo}
                />
              </div>

              <Content paddingLeft="none" paddingRight="none">
                <Heading level={2} className="h3">
                  {card2.heading}
                </Heading>
                <p>{card2.content}</p>
              </Content>
            </Card>
            <Card className={cx.three} styleType="solid" padding="lg">
              <div className={cx.imageThreeWrapper}>
                <GatsbyImage
                  image={card3.image.localFile.childImageSharp.gatsbyImageData}
                  alt=""
                  className={cx.imageThree}
                />
              </div>

              <Content paddingLeft="none" paddingRight="none">
                <Heading level={2} className="h3">
                  {card3.heading}
                </Heading>
                <p>{card3.content}</p>
              </Content>
            </Card>
            <Card className={cx.four} styleType="solid" padding="lg">
              <Columns count="2">
                <div className={cx.imageFourWrapper}>
                  <GatsbyImage
                    image={
                      card4.image.localFile.childImageSharp.gatsbyImageData
                    }
                    alt=""
                    className={cx.imageFour}
                  />
                </div>
                <div className={cx.contentFour}>
                  <Content paddingLeft="none" paddingRight="none">
                    <Heading level={2} className="h3">
                      {card4.heading}
                    </Heading>
                    <p>{card4.content}</p>
                  </Content>
                </div>
              </Columns>
            </Card>
            <DreamCard
              className={cx.five}
              styleType="solid"
              background="blue-vertical"
            >
              <Content paddingLeft="none" paddingRight="none">
                <Heading level={2} className="h3 text--color-white">
                  {card5.heading}
                </Heading>
                <p>{card5.content}</p>
              </Content>
            </DreamCard>
            <Card className={cx.six} styleType="solid" padding="lg">
              <Content paddingLeft="none" paddingRight="none">
                <Heading level={2} className="h3">
                  {card6.heading}
                </Heading>
                <p>{card6.content}</p>
              </Content>
              <GatsbyImage
                image={card6.image.localFile.childImageSharp.gatsbyImageData}
                alt="networks"
                className={cx.imageSix}
              />
            </Card>
          </div>
        </Rows>
      </SectionContent>
    </Section>
  )
}

BenefitsGrid.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default BenefitsGrid
