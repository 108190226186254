import React from "react"
import PropTypes from "prop-types"
import Section from "@components/containers/section"
import { GatsbyImage } from "gatsby-plugin-image"
import { createUseStyles } from "react-jss"
import SectionContent from "@components/containers/section-content"
import Heading from "@components/content/heading"
import { Columns, Rows } from "@components/grid"
import RedPandaMachine from "@src/components/animated/red-panda-machine"
import Content from "@src/components/grid/content"

const useStyles = createUseStyles({
  panda: {
    position: "relative",
    minHeight: 1000,
    alignItems: "center !important",
    marginTop: -80,
  },
  image: {
    minWidth: "fit-content",
  },
  container: {
    display: "inline-flex",
  },
  content: {
    maxWidth: 500,
  },
  header: {
    paddingLeft: "1.5rem",
  },
  accent: {
    transform: "translate(84.5%,70px)",
    zIndex: 2,
    position: "relative",
  },
  columns: {
    columnGap: "4.5rem",
  },
  mobDashboard: {
    display: "none",
  },
  titleContainer: {
    display: "grid",
    gridTemplateColumns: "64px auto",
    alignItems: "center",
    columnGap: "12px",
  },
  contentRows: {
    flexWrap: "no-wrap",

    "@media (max-width: 1280px)": {
      alignItems: "center !important",
      justifyContent: "center !important",
      display: "flex !important",
      flexWrap: "wrap !important",
      columnGap: "64px",
    },
    "@media (max-width: 612px)": {
      rowGap: "64px !important",
    },
  },
  "@media (max-width: 768px)": {
    panda: {
      minHeight: "auto",
      marginTop: "0",
    },
    header: {
      paddingLeft: 0,
      marginTop: "1.5rem",
      paddingRight: "5rem",
    },
    container: {
      display: "unset",
    },
  },
  "@media (max-width: 1280px)": {
    columns: {
      gridTemplateColumns: "1fr !important",
      "--column-row-gap": "64px !important",
    },
    titleContainer: {
      maxWidth: "500px",
    },
  },
})

function Points({ data }) {
  const { pointsItems, image } = data
  const cx = useStyles()

  return (
    <Section theme="light">
      <SectionContent width="wider" className="px-sm">
        <Columns count="2" className={cx.columns} alignment="center">
          <div className={`${cx.panda}`}>
            <RedPandaMachine mobImage={image} />
          </div>
          <div className={`grid__rows ${cx.contentRows}`} data-row-gap="xxl">
            {pointsItems.map(section => (
              <Content paddingLeft="none" paddingRight="none" key={section.id}>
                <div className={cx.titleContainer}>
                  <div className={cx.image}>
                    <GatsbyImage
                      image={
                        section.image.localFile.childImageSharp.gatsbyImageData
                      }
                      alt="icon"
                    />
                  </div>
                  <Heading count={3} className={`${cx.header} h3 `}>
                    {section.heading}
                  </Heading>
                </div>
                <p className={`${cx.content} text--small`}>{section.content}</p>
              </Content>
            ))}
          </div>
        </Columns>
      </SectionContent>
    </Section>
  )
}

Points.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default Points
