import React from "react"
import Main from "@components/layout/main"
import Seo from "@components/seo/seo"
import { graphql } from "gatsby"
import AboveTheFold from "@sections/product/AboveTheFold"
import BenefitsGrid from "@sections/product/BenefitsGrid"
import BenefitsPoints from "@sections/product/BenefitsPoints"
import BlackTextBox from "@sections/product/BlackTextBox"
import CTA from "@sections/product/CTA"
import FirstGrid from "@sections/product/FirstGrid"
import Points from "@sections/product/Points"
import Quote from "@sections/product/Quote"
import TrustedByKnownBrands from "@sections/product/TrustedByKnownBrands"

function ProductPage({ data, location }) {
  const {
    seo,
    firstGrid,
    points,
    quote,
    benefitsPoints,
    trustedBrands,
    ctaBar,
    blackTextBox,
    benefitsGrid,
    aboveTheFold,
  } = data.strapiPageProductV2

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <AboveTheFold data={aboveTheFold} />
      <FirstGrid data={firstGrid} />
      <BlackTextBox data={blackTextBox} />
      <Points data={points} />
      <Quote data={quote} />
      <BenefitsGrid data={benefitsGrid} />
      <BenefitsPoints data={benefitsPoints} />
      <TrustedByKnownBrands data={trustedBrands} />
      <CTA data={ctaBar} />
    </Main>
  )
}

export const query = graphql`
  {
    strapiPageProductV2 {
      aboveTheFold {
        content
        heading
        button {
          buttonNewTab
          buttonText
          buttonURL
        }
        initialFigure
        logoRow {
          id
          logos {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alternativeText
          }
        }
      }
      benefitsGrid {
        heading
        card1 {
          content
          heading
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        card2 {
          content
          heading
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        card3 {
          content
          heading
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        card4 {
          content
          heading
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        card5 {
          content
          heading
        }
        card6 {
          content
          heading
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
        }
      }
      benefitsPoints {
        heading
        items {
          content
          heading
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          id
        }
      }
      blackTextBox {
        id
        quotes
      }
      ctaBar {
        button {
          buttonText
          buttonURL
          buttonNewTab
        }
        content
        heading
        image {
          localFile {
            publicURL
          }
        }
      }
      firstGrid {
        animatedQuote {
          content
          quoteText
        }
        card1 {
          buttonText
          buttonURL
          buttonNewTab
          content
          heading
          preHeading
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        card2 {
          buttonText
          buttonURL
          buttonNewTab
          content
          heading
          preHeading
          image {
            localFile {
              publicURL
            }
          }
        }
        card4 {
          buttonText
          buttonURL
          buttonNewTab
          content
          heading
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          preHeading
        }
        card5 {
          buttonText
          buttonURL
          buttonNewTab
          content
          heading
          preHeading
        }
        card6 {
          buttonText
          buttonNewTab
          buttonURL
          content
          heading
          preHeading
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
      points {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        pointsItems {
          content
          heading
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
      quote {
        button {
          buttonNewTab
          buttonText
          buttonURL
        }
        heading
        quoteText
      }
      seo {
        metaDesc
        metaTitle
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      trustedBrands {
        cardOne {
          buttonText
          buttonURL
          buttonNewTab
          image {
            localFile {
              publicURL
            }
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
          }
        }
        cardTwo {
          buttonText
          buttonURL
          buttonNewTab
          image {
            localFile {
              publicURL
            }
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`

export default ProductPage
