import React, { useLayoutEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { gsap, MotionPathPlugin } from 'gsap/all';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import redPanda from '../../images/red-panda-machine/red-panda.png';
import facebook from '../../images/red-panda-machine/facebook-icon.png';
import youtube from '../../images/red-panda-machine/youtube-icon.png';
import twitter from '../../images/red-panda-machine/twitter-icon.png';
import linkedin from '../../images/red-panda-machine/linkedin-icon.png';
import google from '../../images/red-panda-machine/google-icon.png';
import dashboard from '../../images/red-panda-machine/dashboard.png';
import good from '../../images/red-panda-machine/good-icon.png';
import sus from '../../images/red-panda-machine/sus-icon.png';
import bad from '../../images/red-panda-machine/bad-icon.png';
import diamonds from '../../images/red-panda-machine/diamonds.png';

gsap.registerPlugin(MotionPathPlugin);

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    marginLeft: '25%',
  },
  square: {
    height: 6,
    width: 6,
    background: 'black',
    position: 'absolute',
    transform: 'rotate(45deg)',
    left: 150,
    zIndex: 1,
  },
  funnels: {
    position: 'relative',
    top: 140,
  },
  innerLeft: {
    position: 'absolute',
    left: 72,
  },
  middle: {
    position: 'absolute',
    left: 148,
  },
  innerRight: {
    position: 'absolute',
    left: 146,
  },
  right: {
    position: 'absolute',
    left: 148,
  },
  secondFunnels: {
    position: 'relative',
    top: 420,
  },
  good: {
    position: 'absolute',
    left: -10,
    zIndex: -1,
  },
  sus: {
    position: 'absolute',
    left: 148,
    zIndex: -1,
  },
  bad: {
    position: 'absolute',
    left: 297,
    zIndex: -1,
  },
  redPanda: {
    position: 'absolute',
    width: 66,
    left: 118,
    zIndex: 2,
    top: -10,
  },
  dashboard: {
    position: 'absolute',
    width: 500,
    maxWidth: 500,
    left: -102,
    top: 260,
    zIndex: 3,
  },
  diamonds: {
    position: 'absolute',
    width: 480,
    minWidth: 480,
    left: -91,
    top: 80,
    zIndex: 2,
  },
  facebook: {
    position: 'absolute',
    width: 40,
    top: 205,
    left: -15,
    zIndex: 2,
  },
  youtube: {
    position: 'absolute',
    width: 40,
    top: 140,
    left: 57,
    zIndex: 2,
  },
  twitter: {
    position: 'absolute',
    width: 40,
    top: 105,
    left: 133,
    zIndex: 2,
  },
  linkedin: {
    position: 'absolute',
    width: 40,
    top: 175,
    left: 207,
    zIndex: 2,
  },
  google: {
    position: 'absolute',
    width: 40,
    top: 140,
    left: 280,
    zIndex: 2,
  },
  goodIcon: {
    position: 'absolute',
    width: 40,
    top: 22,
    left: -25,
    zIndex: 2,
  },
  susIcon: {
    position: 'absolute',
    width: 40,
    top: 22,
    left: 132,
    zIndex: 2,
  },
  badIcon: {
    position: 'absolute',
    width: 40,
    top: 22,
    left: 282,
    zIndex: 2,
  },
  facebookCircle: {
    position: 'absolute',
    width: 40,
    top: 205,
    left: -15,
    zIndex: 1,
  },
  youtubeCircle: {
    position: 'absolute',
    width: 40,
    top: 140,
    left: 57,
    zIndex: 1,
  },
  twitterCircle: {
    position: 'absolute',
    width: 40,
    top: 105,
    left: 133,
    zIndex: 1,
  },
  linkedinCircle: {
    position: 'absolute',
    width: 40,
    top: 175,
    left: 207,
    zIndex: 1,
  },
  googleCircle: {
    position: 'absolute',
    width: 40,
    top: 140,
    left: 280,
    zIndex: 1,
  },
  mobDashboard: {
    display: 'none',
    maxWidth: '100%',
    position: 'relative',
    paddingTop: 50,
  },
  leftGood: {
    position: 'absolute',
    width: 40,
    top: 22,
    left: -25,
    zIndex: 1,
  },
  middleSus: {
    position: 'absolute',
    width: 40,
    top: 22,
    left: 132,
    zIndex: 1,
  },
  rightBad: {
    position: 'absolute',
    width: 40,
    top: 22,
    left: 282,
    zIndex: 1,
  },
  '@media (max-width: 768px)': {
    mobDashboard: {
      display: 'block',
      width: '100% !important',
    },
  },
  '@media (max-width: 1280px)': {
    wrapper: {
      position: 'relative',
      marginLeft: '30%',
    },
  },
});

function RedPandaMachine({ mobImage }) {
  const cx = useStyles();

  useLayoutEffect(() => {
    const funnels = ['left', 'innerLeft', 'middle', 'innerRight', 'right'];
    const filterFunnels = ['good', 'sus', 'bad'];
    const filterClick = gsap.timeline({
      repeatRefresh: true,
    });

    function runAnimation(funnel = 'right', filter = 'good') {
      const background = {
        good: { one: '#6CC16C', two: '#9BCC9E' },
        sus: { one: '#F7BF23', two: '#F3DC9B' },
        bad: { one: '#E8715D', two: '#F2AAA1' },
      };

      const circleTimings = {
        leftCircle: 2.4,
        innerLeftCircle: 1.7,
        middleCircle: 1.3,
        innerRightCircle: 2.1,
        rightCircle: 2.0,
      };

      filterClick.clear();
      filterClick.add(gsap.set('#square', { background: 'black' }));
      filterClick.add(gsap.set([`#${funnel}Circle`, `#${filter}Circle`], { opacity: 1, scale: 1 }));

      filterClick.add(gsap.to('#square', {
        duration: 3,
        ease: 'none',
        immediateRender: true,
        motionPath: {
          path: `#${funnel}`,
          align: `#${funnel}`,
          autoRotate: false,
          alignOrigin: [0.5, 0.5],
          start: 1,
          end: 0,
        },
      }));

      filterClick.add(gsap.to(`#${funnel}Circle`, { duration: 0.5, opacity: 0, scale: 1.5 }), circleTimings[`${funnel}Circle`]);
      filterClick.add(gsap.set('#square', { background: background[filter].one }));
      filterClick.add(gsap.set('#square', { background: background[filter].two }), 4);

      filterClick.add(gsap.to('#square', {
        duration: 2,
        ease: 'none',
        delay: 1.5,
        immediateRender: true,
        motionPath: {
          path: `#${filter}`,
          align: `#${filter}`,
          autoRotate: false,
          alignOrigin: [0.5, 0.5],
          start: 1,
          end: 0,
        },
      }));

      filterClick.add(gsap.to(`#${filter}Circle`, { duration: 0.5, opacity: 0, scale: 1.5 }), 5.7);

      filterClick.eventCallback('onComplete', runAnimation, [funnels[Math.floor(Math.random() * (5))], filterFunnels[Math.floor(Math.random() * (4))]]);
    }

    runAnimation();
  }, []);

  return (
    <>
      <div className={`${cx.wrapper} hidden--tab-down`}>
        <div id="square" className={cx.square} />

        <div className={cx.funnels}>
          <img src={redPanda} className={cx.redPanda} alt="panda" />
          <img src={facebook} className={cx.facebook} alt="facebook" />
          <img src={youtube} className={cx.youtube} alt="youtube" />
          <img src={twitter} className={cx.twitter} alt="twitter" />
          <img src={linkedin} className={cx.linkedin} alt="linkedin" />
          <img src={google} className={cx.google} alt="google" />

          <svg id="leftCircle" className={cx.facebookCircle} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="49" cy="49" r="49" fill="#6E86EA" />
          </svg>

          <svg id="innerLeftCircle" className={cx.youtubeCircle} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="49" cy="49" r="49" fill="#E8715D" />
          </svg>

          <svg id="middleCircle" className={cx.twitterCircle} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="49" cy="49" r="49" fill="#61C0D6" />
          </svg>

          <svg id="innerRightCircle" className={cx.linkedinCircle} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="49" cy="49" r="49" fill="#6372E0" />
          </svg>

          <svg id="rightCircle" className={cx.googleCircle} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="49" cy="49" r="49" fill="#AF76D3" />
          </svg>

          <svg className={cx.left} xmlns="http://www.w3.org/2000/svg" width="158.124" height="269.917" viewBox="0 0 158.124 269.917">
            <path id="left" d="M 6.0006 270.1406 L 5.0006 140.0106 C 5.4406 125.6406 21.4606 112.7406 45.9206 107.0706 L 152.2506 82.4106 H 152.2506 L 152.2406 0.0006" fill="none" stroke="#e5e5e5" strokeMiterlimit="10" strokeWidth="10" />
          </svg>

          <svg className={cx.innerLeft} xmlns="http://www.w3.org/2000/svg" width="84.28" height="270.147" viewBox="0 0 84.28 270.147">
            <path id="innerLeft" d="M 5 270.1424 V 148.0324 C 5 137.6624 11.63 127.5924 23.77 119.5924 L 79.12 82.9624 H 79.12 L 79.16 0.0024" fill="none" stroke="#e5e5e5" strokeMiterlimit="10" strokeWidth="10" />
          </svg>

          <svg className={cx.middle} xmlns="http://www.w3.org/2000/svg" width="10.16" height="269.587" viewBox="0 0 10.16 269.587">
            <path id="middle" d="M 5.3663 270.1468 C 5.2442 180.1001 5.1221 90.0534 5 0.0068" fill="none" stroke="#e5e5e5" strokeMiterlimit="10" strokeWidth="10" />
          </svg>

          <svg className={cx.innerRight} xmlns="http://www.w3.org/2000/svg" width="85.55" height="269.117" viewBox="0 0 85.55 269.117">
            <path id="innerRight" d="M 80.55 269.1246 V 147.0246 C 80.55 136.6546 73.99 126.5746 61.99 118.5746 L 5.24 81.9546 H 5.24 L 5 0.0146" fill="none" stroke="#e5e5e5" strokeMiterlimit="10" strokeWidth="10" />
          </svg>

          <svg className={cx.right} xmlns="http://www.w3.org/2000/svg" width="157.84" height="269.172" viewBox="0 0 157.84 269.172">
            <path id="right" d="M 151.95 270.0956 L 152.95 139.9656 C 152.51 125.5956 136.49 112.6956 112.03 107.0256 L 5.7 82.3656 L 5.01 82.4106 L 5 0.0006" fill="none" stroke="#e5e5e5" strokeMiterlimit="10" strokeWidth="10" />
          </svg>
          <img src={dashboard} className={cx.dashboard} alt="dashboard" />

        </div>

        <div className={cx.secondFunnels}>

          <svg className={cx.good} xmlns="http://www.w3.org/2000/svg" width="10.65" height="199.835" viewBox="0 0 10.65 199.835">
            <path d="M 5.0003 94.74 C 5.0002 63.16 5.0001 31.58 5 0" fill="none" stroke="#e5e5e5" strokeMiterlimit="10" strokeWidth="10" />
            <path id="good" d="M 5.0003 194.74 C 5.0002 63.16 5.0001 31.58 5 0" fill="none" stroke="none" strokeMiterlimit="10" strokeWidth="10" />
          </svg>

          <svg className={cx.sus} xmlns="http://www.w3.org/2000/svg" width="10.65" height="199.835" viewBox="0 0 10.65 199.835">
            <path d="M 5.0003 94.74 C 5.0002 63.16 5.0001 31.58 5 0" fill="none" stroke="#e5e5e5" strokeMiterlimit="10" strokeWidth="10" />
            <path id="sus" d="M 5.0003 194.74 C 5.0002 63.16 5.0001 31.58 5 0" fill="none" stroke="none" strokeMiterlimit="10" strokeWidth="10" />
          </svg>

          <svg className={cx.bad} xmlns="http://www.w3.org/2000/svg" width="10.65" height="199.835" viewBox="0 0 10.65 199.835">
            <path d="M 5.0003 94.74 C 5.0002 63.16 5.0001 31.58 5 0" fill="none" stroke="#e5e5e5" strokeMiterlimit="10" strokeWidth="10" />
            <path id="bad" d="M 5.0003 194.74 C 5.0002 63.16 5.0001 31.58 5 0" fill="none" stroke="none" strokeMiterlimit="10" strokeWidth="10" />
          </svg>

          <img src={good} className={cx.goodIcon} alt="good clicks" />
          <img src={sus} className={cx.susIcon} alt="suspicious clicks" />
          <img src={bad} className={cx.badIcon} alt="bad clicks" />
          <img src={diamonds} className={cx.diamonds} alt="diamonds" />

          <svg id="goodCircle" className={cx.leftGood} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="49" cy="49" r="49" fill="#6CC16C" />
          </svg>

          <svg id="susCircle" className={cx.middleSus} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="49" cy="49" r="49" fill="#F7BF23" />
          </svg>

          <svg id="badCircle" className={cx.rightBad} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="49" cy="49" r="49" fill="#E8715D" />
          </svg>

        </div>
      </div>
      <div className={cx.mobView}>
        <GatsbyImage image={mobImage.localFile.childImageSharp.gatsbyImageData} className={cx.mobDashboard} alt="mobile dash" />
      </div>
    </>
  );
}

export default RedPandaMachine;

RedPandaMachine.propTypes = {
  mobImage: PropTypes.object.isRequired,
};
