import React from "react"
import PropTypes from "prop-types"
import SectionContent from "@components/containers/section-content"
import { createUseStyles } from "react-jss"
import Heading from "@components/content/heading"
import { Columns, Rows } from "@components/grid"
import Card from "@components/containers/card"
import Button from "@components/interactive/button"
import { GatsbyImage } from "gatsby-plugin-image"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import CssAccentGroup from "@components/accents/css-accent-group"
import SectionBackground from "@components/containers/section-background"
import Link from "@components/content/link"
import Section from "@src/components/containers/section"

const useStyles = createUseStyles({
  cardOne: data => ({
    backgroundImage: `linear-gradient(to right, rgba(1, 144, 0, 0.8), rgba(255, 212, 3, 0.8)), url("${data.cardOne.image.localFile.publicURL}")`,
    minHeight: 360,
    width: "100%",
    transition: ".2s ease",
    "&:hover": {
      opacity: "0.5",
    },
  }),
  cardTwo: data => ({
    backgroundImage: `linear-gradient(to right, rgba(232, 65, 24, 0.8), rgba(17, 26, 149, 0.8)), url("${data.cardTwo.image.localFile.publicURL}")`,
    minHeight: 360,
    width: "100%",
    transition: ".2s ease",
    "&:hover": {
      opacity: "0.5",
    },
  }),
  button: {
    "& svg": {
      marginLeft: "0.1rem",
      fontSize: "1.2rem",
      verticalAlign: "-0.20em",
    },
  },
  logoGroup: {
    bottom: "1rem",
    left: "3rem",
    position: "absolute",
  },
  wrapper: {
    height: 360,
    width: 578,
  },
  accentOne: {
    transform: "translate(17%,40%)",
  },
  accentTwo: {
    transform: "translate(82.5%,466px)",
  },
  "@media (max-width: 1280px)": {
    accentOne: {
      transform: "translate(2%,40%)",
    },
    accentTwo: {
      transform: "translate(96.7%,466px)",
    },
  },
  "@media (max-width: 828px)": {
    accentOne: {
      transform: "translate(-1.2%,110px)",
    },
    accentTwo: {
      display: "none",
    },
  },
})

function TrustedByKnownBrands({ data }) {
  const { cardOne, cardTwo } = data
  const cx = useStyles(data)

  const accentData = [
    {
      size: 22,
      color: "#05C46B",
      opacity: 0.8,
      left: 17,
      top: 30,
    },
    {
      size: 11,
      color: "#FFA801",
      opacity: 0.3,
      top: 50,
    },
  ]

  const bottomAccent = [
    {
      size: 20,
      color: "#E84118",
      opacity: 0.3,
      left: 0,
      top: 0,
    },
  ]

  return (
    <Section theme="light">
      <SectionContent paddingTop="none">
        <Rows gap="lg">
          <Heading level={3} className="h1 text--center px-lg">
            <span className="text-gradient text-gradient__red-pink">
              Trusted
            </span>{" "}
            By Known Brands
          </Heading>
          <Columns count="2" colgap rowgap>
            <Link
              openTab={data.cardOne.buttonNewTab}
              to={data.cardOne.buttonURL}
            >
              <Card
                styleType="solid"
                className={`${cx.cardOne} px-lg py-md relative`}
              >
                <div className={`${cx.logoGroup} wrapper`}>
                  <Rows gap="none">
                    <GatsbyImage
                      image={
                        cardOne.logo.localFile.childImageSharp.gatsbyImageData
                      }
                      alt="logo"
                    />{" "}
                    <Button
                      icon={faCaretRight}
                      size="lg"
                      styletype=""
                      className={`${cx.button} pl-none text--color-white`}
                    >
                      {data.cardOne.buttonText}
                    </Button>
                  </Rows>
                </div>
              </Card>
            </Link>
            <Link
              openTab={data.cardTwo.buttonNewTab}
              to={data.cardTwo.buttonURL}
            >
              <Card
                styleType="solid"
                className={`${cx.cardTwo} px-lg py-md relative`}
              >
                <div className={cx.logoGroup}>
                  <Rows gap="none">
                    <GatsbyImage
                      image={
                        cardTwo.logo.localFile.childImageSharp.gatsbyImageData
                      }
                      alt="logo"
                    />{" "}
                    <Button
                      icon={faCaretRight}
                      size="lg"
                      styletype=""
                      className={`${cx.button} pl-none text--color-white`}
                    >
                      {data.cardTwo.buttonText}
                    </Button>
                  </Rows>
                </div>
              </Card>
            </Link>
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

TrustedByKnownBrands.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default TrustedByKnownBrands
