import React, { useLayoutEffect } from "react"
import { createUseStyles } from "react-jss"
import { gsap } from "gsap"
import PropTypes from "prop-types"
import DreamCard from "../containers/dream-card"

const useStyles = createUseStyles({
  wrapper: {
    position: "relative",
  },
  quote: {
    position: "absolute",
    height: "100%",
  },
  headingText: {
    position: "relative",
    fontSize: "32px !important",
    lineHeight: "44.8px !important",
  },
  quoteText: {
    position: "absolute",
    bottom: 0,
  },
})

function AnimatedQuoteBox({ quotes, timing, ...rest }) {
  const cx = useStyles()

  const ids = Array.from(quotes, (_, index) => `#quote-${index}`)

  useLayoutEffect(() => {
    const timeline = gsap.timeline({ repeat: -1 })
    // TODO adjust initial and final duration to easing  Lee.T 08/04
    ids.forEach(id => {
      timeline.to(id, { opacity: 1, duration: 1 })
      timeline.to(id, { opacity: 1, duration: timing })
      timeline.to(id, { opacity: 0, duration: 1 })
    })
  }, [])

  return (
    <DreamCard background="blue" {...rest}>
      <div className={cx.wrapper}>
        {quotes.map((card, index) => (
          <div
            id={`quote-${index}`}
            className={cx.quote}
            style={{ opacity: 0 }}
            key={card.id}
          >
            <header className="content__header">
              <p className={`h3 heading text--color-white ${cx.headingText}`}>
                {card.content}
              </p>
            </header>
            <p
              className={`text--color-white text--small text--strong ${cx.quoteText}`}
            >
              {card.quoteText}
            </p>
          </div>
        ))}
      </div>
    </DreamCard>
  )
}

export default AnimatedQuoteBox

AnimatedQuoteBox.defaultProps = {
  timing: 5,
}

AnimatedQuoteBox.propTypes = {
  // Quotes data to use. Each array element should include an object with content, quoteName & id
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      quoteName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  // The time per quote
  timing: PropTypes.number,
}
