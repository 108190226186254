import React from "react"
import PropTypes from "prop-types"
import Section from "@components/containers/section"
import AnimatedQuote from "@components/animated/animated-quote-box"
import DreamCard from "@components/containers/dream-card"
import { Columns } from "@components/grid"
import Card from "@components/containers/card"
import Heading from "@components/content/heading"
import Button from "@components/interactive/button"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { createUseStyles } from "react-jss"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Content from "@src/components/grid/content"
import CtaGroup from "@src/components/containers/cta-group"

const useStyles = createUseStyles(theme => ({
  container: {
    display: "grid",
    width: "100%",
    maxWidth: 1200,
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr 1fr 1fr",
    gridTemplateAreas:
      '"seven seven" "one two" "one three" "four four" "five six"',
    gap: "24px 24px",
    paddingBottom: 176,

    "& svg": {
      marginLeft: "0.1rem",
      fontSize: "1.2rem",
      verticalAlign: "-0.20em",
    },

    "& > div.card": {
      backgroundColor: theme.colors.lights.white,
      display: "block",
      // Hack for Safari rounded corners
      transform: "translateZ(0)",
    },

    "& a": {
      paddingLeft: 0,
    },

    "& p": {
      fontSize: 18,
    },
  },
  one: {
    gridArea: "one",
    overflow: "hidden",
    position: "relative",
    paddingTop: "500px !important",
  },
  two: props => ({
    gridArea: "two",
    overflow: "hidden",
    position: "relative",
    paddingTop: "120px !important",
    backgroundImage: `url("${props.data.card2.image.localFile.publicURL}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "top center",
  }),
  three: {
    gridArea: "three",
    minHeight: 360,
  },
  four: {
    gridArea: "four",
    overflow: "hidden",
    position: "relative",
  },
  five: {
    gridArea: "five",
    width: "auto",
    display: "flex !important",
    flexDirection: "column",

    "& *": {
      color: "#fff",
    },

    "& > div": {
      marginTop: "auto",
    },
  },
  six: {
    gridArea: "six",
    position: "relative",
    overflow: "hidden",

    "& p": {
      maxWidth: 315,
    },
  },
  seven: {
    gridArea: "seven",
    position: "relative",
    overflow: "hidden",
  },
  imageOne: {
    position: "absolute",
    top: 32,
    right: 0,
    left: 0,
  },
  contentFour: {
    marginTop: "auto",
  },
  contentSeven: {
    marginTop: "auto",
    gridColumnStart: 2,
  },
  imageFourWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "calc(100% + 6rem)",
    position: "absolute",
    width: "50%",
    top: 0,
    right: 0,
  },
  imageFour: {
    top: "-3rem",
    right: "-4rem",
    width: "100%",
    height: "auto",
  },
  imageSix: {
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
  imageSevenWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "calc(100% + 6rem)",
    position: "absolute",
    maxWidth: "570px",
    top: 0,
    left: 0,
  },
  imageSeven: {
    opacity: 1,
    position: "relative",
    margin: `-${theme.spacing.lg} -${theme.spacing.lg} 0`,
    top: "-1.25rem",
    left: "-3rem",
  },
  "@media(max-width: 1200px)": {
    two: {
      "&$two": {
        backgroundSize: "contain !important",
      },
    },

    imageFour: {
      opacity: 0.3,
    },
    imageSeven: {
      left: "-12rem",
    },
    container: {
      padding: "0 24px 128px",
    },
  },
  "@media (max-width: 768px)": {
    imageFour: {
      maxWidth: "none",
      width: `calc(100vw - ${theme.spacing.lg})`,
    },
    imageSevenWrapper: {
      height: "auto",
      maxWidth: "1000px",
    },
    contentSeven: {
      marginTop: "300px",
    },
    imageSeven: {
      left: 0,
      top: 0,
      margin: "-220px 0 0 0",
    },
  },
  "@media (max-width: 640px)": {
    two: {
      "&$two": {
        paddingTop: "128px !important",
        paddingBottom: "24px !important",
      },
    },
    four: {
      "& > div": {
        display: "flex",
        flexDirection: "column-reverse",
      },
    },
    imageFourWrapper: {
      position: "relative",
      width: "100%",
    },
    imageFour: {
      opacity: 1,
      position: "relative",
      margin: `-${theme.spacing.lg} -${theme.spacing.lg} 0`,
      top: "-1.25rem",
      right: "-3rem",
    },
    imageSeven: {
      margin: "-100px 0 0 0",
    },
    container: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr",
      gridTemplateAreas: '"seven" "one" "two" "three" "four" "five" "six"',
      padding: 0,
      marginBottom: "80px",

      "& > div": {
        margin: "8px 24px",
      },
    },
    one: {
      paddingTop: "0 !important",
    },
    imageOne: {
      position: "relative",
      margin: "0 -3rem",
      maxWidth: `calc(100vw - ${theme.spacing.lg})`,
    },
    three: {
      "& > div": {
        minHeight: 500,
      },
    },
  },
  "@media (max-width: 490px)": {
    imageSix: {
      marginBottom: "-2rem",
    },
    imageSeven: {
      margin: 0,
    },
  },
}))

function FirstGrid(props) {
  const { data } = props
  const { card1, card2, card4, card5, animatedQuote, card6 } = data
  const cx = useStyles({ ...props })

  return (
    <Section theme="light">
      {/* Empty div needed for Safari to properly size the grid ~ luke */}
      <div>
        <div className={cx.container}>
          <Card className={cx.one} styleType="solid" padding="lg">
            <GatsbyImage
              image={card1.image.localFile.childImageSharp.gatsbyImageData}
              alt=""
              className={cx.imageOne}
            />
            <Content gap="md" className="pt-md mob-pt-none">
              <Heading
                level={2}
                preheading={card1.preHeading}
                preHeadingClasses="text--md"
                className="h3"
              >
                {card1.heading}
              </Heading>
              <p>{card1.content}</p>
              <Button
                styletype=""
                href={card1.buttonURL}
                className="text--color-black"
                icon={faCaretRight}
                size="lg"
                openTab={card1.buttonNewTab}
              >
                {card1.buttonText}
              </Button>
            </Content>
          </Card>
          <Card className={cx.two} styleType="solid" padding="lg">
            <Content gap="md">
              <Heading
                level={2}
                className="h3"
                preheading={card2.preHeading}
                preHeadingClasses="text--md text--color-red"
              >
                {card2.heading}
              </Heading>
              <p>{card2.content}</p>
              <Button
                styletype=""
                href={card2.buttonURL}
                className="text--color-black"
                icon={faCaretRight}
                size="lg"
                openTab={card2.buttonNewTab}
              >
                {card2.buttonText}
              </Button>
            </Content>
          </Card>

          <div className={cx.three}>
            <AnimatedQuote
              timing={5}
              quotes={animatedQuote}
              styleType="solid"
            />
          </div>

          <Card className={cx.four} styleType="solid" padding="lg">
            <Columns className="height--full" count="2" alignment="center">
              <div className={cx.contentFour}>
                <Content paddingLeft="none" gap="md">
                  <Heading
                    level={2}
                    className="h3"
                    preheading={card4.preHeading}
                    preHeadingClasses="text--md text--color-nebula"
                  >
                    {card4.heading}
                  </Heading>
                  <p>{card4.content}</p>
                  <Button
                    styletype=""
                    href={card4.buttonURL}
                    className="text--color-black"
                    icon={faCaretRight}
                    size="lg"
                    openTab={card4.buttonNewTab}
                  >
                    {card4.buttonText}
                  </Button>
                </Content>
              </div>
              <div className={cx.imageFourWrapper}>
                <GatsbyImage
                  image={card4.image.localFile.childImageSharp.gatsbyImageData}
                  alt=""
                  className={cx.imageFour}
                />
              </div>
            </Columns>
          </Card>

          <DreamCard className={cx.five} background="pink" styleType="solid">
            <div>
              <Content gap="md">
                <Heading
                  level={2}
                  className="h3"
                  preheading={card5.preHeading}
                  preHeadingClasses="text--md text--color-white"
                >
                  {card5.heading}
                </Heading>
                <p>{card5.content}</p>
                <Button
                  styletype=""
                  href={card5.buttonURL}
                  icon={faCaretRight}
                  size="lg"
                  openTab={card5.buttonNewTab}
                >
                  {card5.buttonText}
                </Button>
              </Content>
            </div>
          </DreamCard>

          <Card className={cx.six} styleType="solid" padding="lg">
            <div>
              <Content gap="md">
                <Heading
                  level={2}
                  className="h3"
                  preheading={card6.preHeading}
                  preHeadingClasses="text--md text--color-green"
                >
                  {card6.heading}
                </Heading>
                <p>{card6.content}</p>
                <Button
                  styletype=""
                  href={card6.buttonURL}
                  className="text--color-black"
                  icon={faCaretRight}
                  size="lg"
                  openTab={card6.buttonNewTab}
                >
                  {card6.buttonText}
                </Button>
              </Content>
            </div>
            <GatsbyImage
              image={card6.image.localFile.childImageSharp.gatsbyImageData}
              alt=""
              className={cx.imageSix}
            />
          </Card>

          <Card className={cx.seven} styleType="solid" padding="lg">
            <Columns className="height--full" count="2" alignment="center">
              <div className={cx.contentSeven}>
                <Content paddingLeft="md" paddingRight="none" gap="md">
                  <Heading
                    alignment="left"
                    level={2}
                    className="h3"
                    preheading="Trusted Worldwide"
                    preHeadingClasses="text--md text--color-coral"
                  >
                    Powering the World’s Fastest Growing Companies
                  </Heading>
                  <p>
                    Thousands of multinational brands and rapidly-scaling
                    businesses trust Lunio to end wasted spend and maximise
                    marketing efficiency. See why our customers love us.
                  </p>
                  <CtaGroup>
                    <Button
                      styletype=""
                      href="/success-stories/"
                      className="pr-none text--color-black"
                      icon={faCaretRight}
                      size="lg"
                    >
                      Our Successes
                    </Button>
                  </CtaGroup>
                </Content>
              </div>
              <div className={cx.imageSevenWrapper}>
                <StaticImage
                  src="../../../images/product/product-logo-top.png"
                  alt="Lunio customer logos"
                  className={cx.imageSeven}
                />
              </div>
            </Columns>
          </Card>
        </div>
      </div>
    </Section>
  )
}

FirstGrid.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default FirstGrid
