import React, { useLayoutEffect, useEffect, useState } from "react"
import { gsap } from "gsap"
import PropTypes from "prop-types"
import Section from "@components/containers/section"
import SectionContent from "@components/containers/section-content"
import SectionBackground from "@components/containers/section-background"
import { Rows, Columns } from "@components/grid"
import Heading from "@components/content/heading"
// eslint-disable-next-line import/no-unresolved
import { createUseStyles } from "react-jss"
import facebook from "../../../images/logo/logo_0006_facebook.png"
import twitter from "../../../images/logo/social-icons/social_icon_twitter.png"
import reddit from "../../../images/logo/social-icons/social_icon_reddit.png"
import tiktok from "../../../images/logo/social-icons/social_icon_tiktok.png"
import snapchat from "../../../images/logo/logo_0003_google.png"
import linkedin from "../../../images/logo/logo_0007_linkedin.png"
import windows from "../../../images/logo/social-icons/windows_dark.png"
import Content from "@src/components/grid/content"
import Footer from "@src/components/grid/footer"
import CountUp from "react-countup"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faThumbsUp,
  faThumbsDown,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons"
import ProductClearbitForm from "./product-clearbit-form"
import ClearbitConverisonForm from "@src/components-smart/forms/clearbit-conversion-form"
import HeroForm from "@src/sections/home/hero-form"

const useStyles = createUseStyles(() => ({
  logoSect: {
    "@media (max-width: 600px)": {
      display: "grid !important",
      gridTemplateColumns: "1fr 1fr",
    },
  },
  background: {
    height: "100%",
    backgroundSize: "cover",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      height: 620,
      width: "100%",
      background: "linear-gradient(360deg, white 50%, transparent 100%)",
      backgroundClip: "text",
      color: "transparent",
      left: 0,
      right: 0,
      margin: 0,
    },
  },
  dashboardImageContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    left: 0,
    height: "100%",
    width: "100%",
    "@media (max-width: 1480px)": {
      left: "auto",
      right: "-180px",
    },
    "@media (max-width: 1180px)": {
      opacity: 0.3,
    },
    "@media (max-width: 800px)": {
      width: "500px",
      right: -300,
      bottom: -200,
      top: "auto",
    },
  },
  dashboardImageBg: {
    position: "absolute",
    background: "white",
    borderRadius: 24,
    padding: "24px",
    width: 560,
    maxWidth: "100%",
  },
  dashboardImageTransparent: {
    opacity: 0.35,
  },
  dashboardImageLegitimate: {
    right: -200,
    top: "216px",
    "@media (max-width: 900px)": {
      right: -100,
    },
  },
  dashboardImageMain: {
    right: 184,
    top: "372px",
    zIndex: 1,
  },
  dashboardImageSus: {
    right: -80,
    top: "700px",
    "@media (max-width: 1200px)": {
      bottom: "15%",
    },
  },
  dashboardImageIcon: {
    borderRadius: "100%",
    width: 54,
    height: 54,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dashboardImageMainGrey: {
    background: "#f5f5f5",
    borderRadius: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "32px 24px",
    flexDirection: "column",
  },
  imageAnchor: {
    position: "absolute",
    top: "110px",
    width: "100%",
    zIndex: 2,
    "@media (max-width: 1480px)": {
      left: "auto",
      right: "-180px",
    },
    "@media (max-width: 1180px)": {
      opacity: 0.3,
      width: "120%",
    },
    "@media (max-width: 800px)": {
      opacity: 1,
      width: "100%",
    },
    "@media (max-width: 400px)": {
      top: 0,
      bottom: 0,
    },
  },
  icons: {
    backfaceVisibility: "hidden",
  },
  facebookIcon: {
    position: "absolute",
    maxHeight: 75,
    maxWidth: 73,
    top: 200,
    right: 690,
  },
  linkedinIcon: {
    position: "absolute",
    maxHeight: 75,
    maxWidth: 73,
    top: 440,
    right: 40,
  },
  snapchatIcon: {
    position: "absolute",
    maxHeight: 40,
    maxWidth: 38,
    top: 150,
    right: 390,
  },
  twitterIcon: {
    position: "absolute",
    maxHeight: 60,
    maxWidth: 58,
    top: 110,
    right: -10,
  },
  redditIcon: {
    position: "absolute",
    maxHeight: 70,
    maxWidth: 68,
    top: 295,
    right: 170,
  },
  windowsIcon: {
    position: "absolute",
    maxHeight: 80,
    maxWidth: 80,
    top: 620,
    right: 200,
  },
  tiktokIcon: {
    position: "absolute",
    maxHeight: 65,
    maxWidth: 65,
    top: 600,
    right: 460,
  },
  content: {
    maxWidth: 540,
  },
  formInput: {
    height: 46,
    width: 300,
    background: "#FFFFFFA8 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 25px #00000019",
    border: "2px solid #FFFFFF",
    borderRadius: 50,
    opacity: 1,
    fontSize: "16px",
    fontWeight: "normal",
    padding: "11px 2rem 13px",
    lineHeight: "20px",
    transition: "0.2s ease",
    letterSpacing: "0.2px",
    textAlign: "left",
    "&::placeholder": {
      color: "#230637",
    },
  },
  "@media (max-width: 800px)": {
    content: {
      marginBottom: 280,
      "& header": {
        width: "100%",
        "& h2": {
          fontSize: 56,
          lineHeight: "70px",
        },
      },
      "& p": {
        width: "85%",
      },
    },
    formInput: {
      width: "100%",
      marginRight: 0,
      marginBottom: 12,
    },
    background: {
      "&:after": {
        height: 810,
      },
    },
    twitterIcon: {
      display: "none",
      opacity: 0,
    },
    facebookIcon: {
      display: "none",
      opacity: 0,
    },
    tiktokIcon: {
      top: "947px",
      right: "275px",
      maxHeight: "74px",
      maxWidth: "74px",
    },
    windowsIcon: {
      top: "748px",
      right: "28px",
      maxHeight: "56px",
      maxWidth: "56px",
    },
    linkedinIcon: {
      top: "1000px",
      right: "22px",
      maxHeight: "42px",
      maxWidth: "42px",
    },
    redditIcon: {
      display: "none",
      opacity: 0,
    },
    snapchatIcon: {
      display: "none",
      opacity: 0,
    },
  },
}))

function AboveTheFold(props) {
  const { data } = props
  const [email, setEmail] = useState("")
  const { heading, content, logoRow, initialFigure, button } = data

  const cx = useStyles({ ...props })

  // Floating icons functionality
  const tl = gsap.timeline()
  const floatNum = [1, 2, 3, 4, 5, 6, 7]
  useLayoutEffect(() => {
    floatNum.forEach((_, i) => {
      const iterNum = [2, 3, 4]
      const randNum = iterNum[Math.floor(Math.random() * iterNum.length)]
      tl.to(`.above-icons-${i + 1}`, {
        y: "-=10",
        yoyo: true,
        repeat: -1,
        duration: randNum,
        ease: "Power1.easeInOut",
      })
    })
  })

  useLayoutEffect(() => {
    // Change colour of first word in title
    const parts = heading.split(" ")
    const firstWord = parts.shift()
    const newFirstWord = `<span class="text-gradient text-gradient__terra">${firstWord}</span>`
    parts.unshift(newFirstWord)
    let result
    if (parts instanceof Array) {
      result = parts.join(" ")
      const headingObject = document.getElementById("productTitle")
      headingObject.innerHTML = result
    }
  })

  // Set email var and redirect with param
  function submitEmail(event) {
    event.preventDefault()

    if (button.buttonNewTab) {
      window.open(`${button.buttonURL}?email=${email}`, "_blank")
    } else {
      window.location.replace(`${button.buttonURL}?email=${email}`)
    }
  }

  return (
    <Section theme="light" className={cx.section}>
      <SectionBackground>
        {
          //<MeshBackground background={1} className={cx.background} />
        }
        <div className="absolute width--full height--full hero-image hero-image--productbg" />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "40%",
            bottom: "0",
            background: "linear-gradient(rgba(255,255,255,0) 0%, #f5f5f5 90%)",
          }}
        ></div>
        <div className={cx.dashboardImageContainer}>
          <div
            className={`${cx.dashboardImageTransparent} ${cx.dashboardImageBg} ${cx.dashboardImageLegitimate}`}
          >
            <Rows gap="sm">
              <Columns count={1}>
                <div className="pl-xs flex flex--align-center">
                  <div
                    className={`${cx.dashboardImageIcon} text-gradient__terra mr-xs`}
                  >
                    <p className="text--color-white h4">
                      <FontAwesomeIcon icon={faThumbsUp} />
                    </p>
                  </div>
                  <Content paddingLeft="none" gap="xs">
                    <p className="text--xxs text--strong">
                      Legitimate Ad Activity
                    </p>
                    <p className="text--xs text--strong text--color-green">
                      126,800 (76.3%)
                    </p>
                  </Content>
                </div>
              </Columns>
              <Columns count={3}>
                <Content paddingRight="sm" gap="xs">
                  <p className="text--xxs text--strong">Detected:</p>
                  <p className="text--xs text--strong text--color-grey">
                    126,800 (76.3%)
                  </p>
                </Content>
              </Columns>
            </Rows>
          </div>
          <div className={`${cx.dashboardImageMain} ${cx.dashboardImageBg}`}>
            <Rows gap="sm">
              <Columns count={1}>
                <div className="pl-xs flex flex--align-center">
                  <div
                    className={`${cx.dashboardImageIcon} text-gradient__ragnarok mr-xs`}
                  >
                    <p className="text--color-white h4">
                      <FontAwesomeIcon icon={faThumbsDown} />
                    </p>
                  </div>
                  <Content paddingLeft="none" gap="xs">
                    <p className="text--xxs text--strong">
                      Invalid Ad Activity
                    </p>
                    <p className="text--xs text--strong text--color-red">
                      19,061 (17.3%)
                    </p>
                  </Content>
                </div>
              </Columns>
              <Columns count={3}>
                <Content paddingRight="sm" gap="xs">
                  <p className="text--xxs text--strong">Detected:</p>
                  <p className="text--xs text--strong text--color-grey">
                    19,061 (17.3%)
                  </p>
                </Content>
                <Content paddingRight="sm" gap="xs">
                  <p className="text--xxs text--strong">Active Exclusions:</p>
                  <p className="text--xs text--strong text--color-grey">
                    16,896 IPs
                  </p>
                </Content>
                <Content paddingRight="sm" gap="xs">
                  <p className="text--xxs text--strong">Prevented:</p>
                  <p className="text--xs text--strong text--color-grey">
                    57,183
                  </p>
                </Content>
              </Columns>
              <div className={cx.dashboardImageMainGrey}>
                <p className="h4 h4--sm text--align-center">Invalid Costs</p>
                <p className="h2 text--color-red text--align-center">
                  <CountUp
                    start={initialFigure}
                    end={initialFigure + 22321}
                    duration={60}
                    prefix="£"
                    separator=","
                  />
                </p>
              </div>
            </Rows>
          </div>
          <div
            className={`${cx.dashboardImageTransparent} ${cx.dashboardImageBg} ${cx.dashboardImageSus}`}
          >
            <Rows gap="sm">
              <Columns count={1}>
                <div className="pl-xs flex flex--align-center">
                  <div
                    className={`${cx.dashboardImageIcon} text-gradient__coral mr-xs`}
                  >
                    <p className="text--color-white h4">
                      <FontAwesomeIcon icon={faUserSecret} />
                    </p>
                  </div>
                  <Content paddingLeft="none" gap="xs">
                    <p className="text--xxs text--strong">
                      Suspicious Ad Activity
                    </p>
                    <p className="text--xs text--strong text--color-yellow">
                      10,703 (6.4%)
                    </p>
                  </Content>
                </div>
              </Columns>
              <Columns count={3}>
                <Content paddingRight="sm" gap="xs">
                  <p className="text--xxs text--strong">Detected:</p>
                  <p className="text--xs text--strong text--color-grey">
                    10,703 (6.4%)
                  </p>
                </Content>
              </Columns>
            </Rows>
          </div>
        </div>
        <div className={cx.imageAnchor}>
          <img
            className={`${cx.facebookIcon} above-icons-1`}
            alt="Facebook Icon"
            src={facebook}
          />
          <img
            className={`${cx.linkedinIcon} above-icons-2`}
            alt="Linkedin Icon"
            src={linkedin}
          />
          <img
            className={`${cx.redditIcon} above-icons-3`}
            alt="Reddit Icon"
            src={reddit}
          />
          <img
            className={`${cx.snapchatIcon} above-icons-4`}
            alt="Snapchat Icon"
            src={snapchat}
          />
          <img
            className={`${cx.tiktokIcon} above-icons-5`}
            alt="Tiktok Icon"
            src={tiktok}
          />
          <img
            className={`${cx.twitterIcon} above-icons-6`}
            alt="Twitter Icon"
            src={twitter}
          />
          <img
            className={`${cx.windowsIcon} above-icons-7`}
            alt="Windows Icon"
            src={windows}
          />
        </div>
      </SectionBackground>
      <SectionContent paddingTop="lg" paddingBot="xxl">
        <div className={cx.content}>
          <Columns count="1">
            <Content gap="xl" paddingLeft="none" paddingRight="none">
              <Heading
                level={1}
                className="mt-lg text--color-white"
                id="productTitle"
              >
                {heading}
              </Heading>
              <p className="text--color-white">{content}</p>
              <Footer>
                <HeroForm formId="69b91281-36e2-4422-8d78-ee27c31add6f" />
              </Footer>
              <div
                className={`${cx.logoSect} flex flex--wrap-mob mt-md mb-md pc-mb-none`}
              >
                {logoRow.slice(0, 4).map(item => {
                  const imgFile = getImage(item.logos[0].localFile)

                  return (
                    <div className="mr-md flex flex--align-center flex--justify-center">
                      <GatsbyImage
                        style={{
                          filter: "brightness(0) invert(1)",
                          maxWidth: 148,
                        }}
                        className="blog__listing__image"
                        image={imgFile}
                        alt={item.logos[0].alternateText}
                      />
                    </div>
                  )
                })}
              </div>
            </Content>
          </Columns>
        </div>
      </SectionContent>
    </Section>
  )
}

AboveTheFold.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default AboveTheFold
