import React from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"
import Section from "@components/containers/section"
import SectionContent from "@components/containers/section-content"
import { Rows, Columns } from "@components/grid"
import Button from "@components/interactive/button"
import { StaticImage } from "gatsby-plugin-image"
import Content from "@src/components/grid/content"
import Copy from "@src/components/content/copy"
import CtaGroup from "@src/components/containers/cta-group"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"

const useStyles = createUseStyles({
  blockquote: {
    position: "relative",
    color: "#100B15",
  },
  textOpacity: {
    opacity: "50%",
  },
  bgImage: {
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    marginLeft: "auto",
    width: "50%",
  },
  quotemark: {
    position: "absolute",
    fontSize: 65,
    left: -48,
    top: -18,
  },
  headshot: {
    position: "absolute",
    left: 0,
    bottom: -25,
    width: "500px",
  },
  trophy: {
    position: "absolute",
    right: -200,
    bottom: 0,
    width: "260px",
  },
  imageContainer: {
    "@media (max-width: 768px)": {
      position: "absolute",
      right: -300,
    },
    "@media (max-width: 500px)": {
      position: "absolute",
      right: -200,
    },
  },
  "@media (max-width: 1170px)": {
    bgImage: {
      width: "100%",
      backgroundPosition: "bottom",
    },
  },
  "@media (max-width: 768px)": {
    trophy: {
      display: "none",
    },
    headshot: {
      right: 0,
      width: "460px",
      zIndex: "-1",
    },
  },
})

function Quote({ data }) {
  const cx = useStyles()
  const { button, quoteText, heading } = data

  return (
    <Section theme="light">
      <SectionContent paddingTop="xs" paddingBot="xs">
        <div className="border--rounded text-gradient__midgard relative overflow--hidden">
          <Rows className="pr-md tab-pr-none pt-md lap-pt-lg tab-px-md">
            <Columns
              className="pl-md tab-pl-none careers-slider__columns"
              alignment="center"
            >
              <Content className="relative pb-sm lap-pb-lg">
                <FontAwesomeIcon
                  icon={faQuoteLeft}
                  className="absolute text--color-white opc-10 h1"
                  style={{ left: 0, top: 32 }}
                />
                <p className="text--strong text--color-white">{quoteText}</p>
                <Copy
                  className="text--color-white h3 mb-xxs"
                  contentToParse={heading}
                />
                <CtaGroup>
                  <Button
                    color="light"
                    styletype="blank"
                    href="https://lp.lunio.ai/demo-v1/"
                  >
                    Get Started
                  </Button>
                </CtaGroup>
              </Content>
              <Content
                className="flex--justify-end hidden--tab-down"
                paddingRight="none"
              >
                <StaticImage
                  src="../../../images/product/anton-headshot.png"
                  alt="headshot"
                />
              </Content>
            </Columns>
          </Rows>
        </div>
      </SectionContent>
    </Section>
  )
}

Quote.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default Quote

// <Rows>
//           <Columns count="2,1">
//             <blockquote className={`pl-xl pr-lg mob-pr-none tab-pb-xxl`}>
//               <blockquote className={`h2 pr-sm pb-xs ${cx.blockquote}`}>
//                 <span
//                   className={`${cx.quotemark} text-gradient text-gradient__pink-red-270`}
//                 >
//                   &quot;
//                 </span>
//                 {parse(heading)}
//               </blockquote>
//               <p className={`${cx.textOpacity} text--strong pb-xs`}>
//                 {quoteText}
//               </p>
//               <Button
//                 color="coral"
//                 href={button.buttonURL}
//                 openTab={button.buttonNewTab}
//               >
//                 {button.buttonText}
//               </Button>
//             </blockquote>
//             <div className={`relative height--full ${cx.imageContainer}`}>
//               <StaticImage
//                 className={cx.headshot}
//                 src="../../../images/product/anton-headshot.png"
//                 alt="headshot"
//               />
//             </div>
//           </Columns>
//         </Rows>
