import React from "react"
import PropTypes from "prop-types"
import Heading from "@components/content/heading"
import parse from "html-react-parser"
import { createUseStyles } from "react-jss"
import { Columns } from "@components/grid"
import SectionContent from "@components/containers/section-content"
import { GatsbyImage } from "gatsby-plugin-image"
import Rows from "@src/components/grid/rows"
import Section from "@src/components/containers/section"

const useStyles = createUseStyles(theme => ({
  container: {
    paddingLeft: "4.5rem",
    position: "relative",
    maxWidth: 578,
    height: "100%",
    "& p": {
      fontSize: "18px",
      lineHeight: "32px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  image: {
    position: "absolute",
    left: 0,
  },
  accents: {
    transform: "translate(80%,25%)",
  },
  "@media (max-width: 1024px)": {
    accents: {
      transform: "translate(95%,25%)",
    },
  },
  "@media (max-width: 768px)": {
    content: {
      paddingTop: theme.spacing.sm,
      height: "auto",
    },
    image: {
      position: "relative",
      left: 0,
    },
    container: {
      paddingTop: theme.spacing.sm,
      paddingLeft: 0,
    },
  },
}))

function BenefitsPoints({ data }) {
  const { heading, items } = data
  const cx = useStyles()

  return (
    <Section theme="light">
      <SectionContent paddingTop="none" paddingBot="sm">
        <Rows gap="xl">
          <Heading level={4} className="h1 mb-xs pt-md pr-xl">
            {parse(heading)}
          </Heading>
          <Columns count="2">
            {items.map(section => (
              <div className={cx.container}>
                <div>
                  <GatsbyImage
                    image={
                      section.image.localFile.childImageSharp.gatsbyImageData
                    }
                    alt=""
                    className={cx.image}
                  />
                </div>
                <div className={cx.content}>
                  <Heading level={3} className="h3 mb-sm pr-md">
                    {section.heading}
                  </Heading>
                  <p className="mb-sm tab-mb-lg pr-md">{section.content}</p>
                </div>
              </div>
            ))}
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

BenefitsPoints.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default BenefitsPoints
