import React, { useLayoutEffect } from "react"
import PropTypes from "prop-types"
import Section from "@components/containers/section"
import SectionContent from "@components/containers/section-content"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { createUseStyles } from "react-jss"

gsap.registerPlugin(ScrollTrigger)

const useStyles = createUseStyles(theme => ({
  section: {
    height: "fit-content !important",
    maxHeight: "fit-content !important",
    backgroundColor: "#010415",
  },
  content: {
    display: "inline-block",
    margin: "auto",
  },
  strings: {
    transition: "opacity .5s ease",
    color: theme.colors.lights.white,
    fontFamily: theme.fonts.axiforma,
    fontSize: 64,
    opacity: 0.1,
    "&:not(:first-of-type)": {
      marginLeft: 15,
    },
    "&:first-child": {
      // opacity: 1,
    },
  },
  active: {
    opacity: 1,
  },
  "@media (max-width: 640px)": {
    section: {
      minHeight: "800px !important",
    },
    content: {
      display: "grid",
    },
    strings: {
      fontSize: 48,
      "&:not(:first-of-type)": {
        marginLeft: 0,
      },
    },
  },
}))

function BlackTextBox({ data }) {
  const cx = useStyles()

  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      "(min-width: 960px)": function () {
        ScrollTrigger.create({
          trigger: "#animationid0",
          start: "-1000px center",
          end: "100px center",
          toggleClass: `${cx.active}`,
          pinSpacing: true,
        })
        ScrollTrigger.create({
          trigger: "#animationid1",
          start: "30px center",
          end: "230px center",
          toggleClass: `${cx.active}`,
          pinSpacing: true,
        })
        ScrollTrigger.create({
          trigger: "#animationid2",
          start: "160px center",
          end: "1000px center",
          toggleClass: `${cx.active}`,
          pinSpacing: true,
        })
      },
      // mobile/tablet
      "(max-width: 959px)": function () {
        ScrollTrigger.create({
          trigger: "#animationid0",
          start: "-500px center",
          end: "230px center",
          toggleClass: `${cx.active}`,
          pinSpacing: true,
        })
        ScrollTrigger.create({
          trigger: "#animationid1",
          start: "100px center",
          end: "230px center",
          toggleClass: `${cx.active}`,
          pinSpacing: true,
        })
        ScrollTrigger.create({
          trigger: "#animationid2",
          start: "0px center",
          end: "1000px center",
          toggleClass: `${cx.active}`,
          pinSpacing: true,
        })
      },
    })
  })

  return (
    <Section className={cx.section} id="bb-strings">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 160">
        <path
          fill="#f5f5f5"
          fill-opacity="1"
          d="M0,0L120,16C240,32,480,64,720,85.3C960,107,1200,117,1320,122.7L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
        />
      </svg>
      <SectionContent paddingTop="md" paddingBot="md">
        <div className={cx.content}>
          {data.map((item, index) => (
            <span
              key={item.id}
              className={cx.strings}
              id={`animationid${index}`}
            >
              {item.quotes}
            </span>
          ))}
        </div>
      </SectionContent>
      <svg viewBox="0 0 1440 120" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 87L120 90.3C240 93.7 480 100.3 720 95C960 89.7 1200 72.3 1320 63.7L1440 55L1440 121L1320 121C1200 121 960 121 720 121C480 121 240 121 120 121L0 121Z"
          fill="#f5f5f5"
        />
      </svg>
    </Section>
  )
}

BlackTextBox.propTypes = {
  /**
   * Data for the section
   */
  data: PropTypes.object.isRequired,
}

export default BlackTextBox
