import React from "react"
import PropTypes from "prop-types"
import SectionContent from "@components/containers/section-content"
import Section from "@components/containers/section"
import Heading from "@components/content/heading"
import Button from "@components/interactive/button"
import { createUseStyles } from "react-jss"
import parse from "html-react-parser"

const useStyles = createUseStyles(theme => ({
  section: props => ({
    minHeight: 599,
    background:
      "linear-gradient(-65deg, rgba(10,10,10,1) 33%, rgba(68,14,105,1) 66%, rgba(214,66,195,1) 100%) !important",

    "&:before": {
      content: '""',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110%",
      display: "block",
      backgroundImage: `url("${props.data.image.localFile.publicURL}")`,
      position: "absolute",
      inset: 0,
    },
  }),
  content: {
    maxWidth: 590,
  },
  "@media (max-width: 1600px)": {
    section: {
      "&:before": {
        opacity: 0.3,
      },
    },
  },
  "@media (max-width: 960px)": {
    section: {
      "&:before": {
        // Else it leaves a gap as its not centered
        backgroundPosition: "center !important",
      },
    },
  },
  "@media (max-width: 640px)": {
    section: {
      paddingBottom: 352,
      "&:before": {
        opacity: 1,
        backgroundPosition: "100% bottom !important",
        backgroundSize: "640px 352px",
      },
    },
  },
}))

function CTA(props) {
  const { data } = props
  const { button, heading, content } = data
  const cx = useStyles({ ...props })

  return (
    <Section theme="black" className={cx.section}>
      <SectionContent paddingTop="lg" paddingBot="lg">
        <div className={cx.content}>
          <Heading level={3} className="h2 mb-sm text--color-white">
            {parse(heading)}
          </Heading>
          <p className="mb-md text--color-white">{content}</p>
          <Button
            color="yellow-black"
            href={button.buttonURL}
            openTab={button.buttonNewTab}
          >
            {button.buttonText}
          </Button>
        </div>
      </SectionContent>
    </Section>
  )
}

CTA.propTypes = {
  /**
   * Data Object
   */
  data: PropTypes.object.isRequired,
}

export default CTA
